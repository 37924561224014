import axiosIns from '@/libs/axios.js';

class MultipleQuestionService {
    getStudentMCQ(id) {
        return axiosIns.get(`/student-multiple-questions/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    storeAnswer(data)
    {
        return axiosIns.post('student-answer/store', data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    findAnswer(id)
    {
        return axiosIns.get(`/student-answer/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    listing(quizId){
        return axiosIns.get(`multiple-questions-listing/${quizId}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export default new MultipleQuestionService();