<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
      <b-col
      md="12"
      class="my-1"
    >
    <!--
      <b-button    
        variant="primary"
        class="mb-1 float-right"
        to="/create-course"
      >
            Create Lesson
      </b-button>
      -->
    </b-col>
    <b-table
      :fields="fields"
      :items="mcqLists"
      responsive
      class="mb-0"
    >

      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(question)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #cell(option_1)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #cell(option_2)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #cell(option_3)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #cell(option_4)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
    </b-table>
    <!-- pagination -->
    
        
  </b-overlay>
</template>


<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import MultipleQuestionService from '@/services/multipleQuestion.service.js';
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BTable, BProgress,BButton, BContainer, BListGroup, BListGroupItem, BRow, BCol, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BLink, BIcon,BCard,BCardHeader,BCardBody,VBToggle,BCollapse} from 'bootstrap-vue'
  export default {
    components: {
        BCardCode,
        BTable,
        BProgress,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BOverlay,
        BRow,
        BCol,
        BButton,
        BLink,
        BIcon,
        BContainer,
        BCard,
        BCardHeader,
        BCardBody,
        BCollapse,
        BListGroup,
        BListGroupItem
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
      return {
        fields: [
          // A virtual column that doesn't exist in items
          'Sr#',
          // A column that needs custom formatting
          { key: 'question', label: 'Question' },
          { key: 'option_1', label: 'Option 1', tdClass: (value, key, item) => item.answer == 1 ? 'table-success' : ''},
          { key: 'option_2', label: 'Option 2', tdClass: (value, key, item) => item.answer == 2 ? 'table-success' : '' },
          { key: 'option_3', label: 'Option 3', tdClass: (value, key, item) => item.answer == 3 ? 'table-success' : '' },
          { key: 'option_4', label: 'Option 4', tdClass: (value, key, item) => item.answer == 4 ? 'table-success' : '' },
        ],
        id: this.$route.params.quizId,
        
        mcqLists: [],
        showOverlay: false, 
      }
    },
     watch:{
        '$route'(to) {
            this.id = to.params.quizId;
        } 
    },
    methods: {
      listing()
      {
        this.showOverlay = true;
        console.log(this.id)
        MultipleQuestionService.listing(this.id).then(response => {
          this.showOverlay = false;
          this.mcqLists =response.data.data.mcqs
          // this.result = response.data.data.result
          // if(this.result.quiz_answers && this.result.quiz_answers.length > 0) {
          //   this.answers = this.result.quiz_answers;
          // }
          console.log(response.data.data)
        }).catch(error => {
          this.showOverlay = false;
          this.errors = error.response
        });
      },
      rowClass(item, type) {
        console.log(item)
        console.log(type)
        return 'table-success'
      }
    },
    created ()
    {
        this.listing()
    }
  }
</script>